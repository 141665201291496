import {
  CreateCitySiteRequest,
  UpdateCitySiteRequest,
  createSiteV1,
  deleteSiteV1,
  getSitesV1,
  updateSiteV1,
} from '../apis/sitesApi';
import { actionFactory } from '../infrastructure/actionFactory';

export const getOnlineSites = (cityId: string) =>
  actionFactory('SITES_GET', getSitesV1(cityId));

export const createSite = (cityId: string, request: CreateCitySiteRequest) =>
  actionFactory('PARTNER_VIDEO_SITE_CREATE', createSiteV1(cityId, request));

export const updateSite = (cityId: string, request: UpdateCitySiteRequest) =>
  actionFactory('PARTNER_VIDEO_SITE_UPDATE', updateSiteV1(cityId, request));

export const deleteSite = (cityId: string, siteId: string) =>
  actionFactory('PARTNER_VIDEO_SITE_DELETE', deleteSiteV1(cityId, siteId));
