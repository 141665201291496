import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  NotificationManager,
} from '@alexpireddu/map-ui';
import { FC, useState, useCallback } from 'react';
import { deleteSite } from '../../../actions';
import { CitySite } from '../../../apis/sitesApi';
import { useAction } from '../../../hooks/useActions';
import { ReloadCallbackHandler } from '../../../infrastructure/commonTypes';

export interface DeleteSiteModalProps {
  onClose: ReloadCallbackHandler;
  site: CitySite;
  currentCityId?: string;
}

const DeleteSiteModal: FC<DeleteSiteModalProps> = ({
  onClose,
  site,
  currentCityId,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const siteDelete = useAction(deleteSite);

  const handleConfirm = useCallback(() => {
    setLoading(true);
    siteDelete(currentCityId!, site.id)
      .then(() => {
        setLoading(false);

        NotificationManager.notify({
          type: 'success',
          message: 'Impianto eliminato',
        });

        onClose(true);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [currentCityId, siteDelete, onClose, site.id]);

  return (
    <Modal>
      <ModalHeader>Elimina impianto</ModalHeader>
      <ModalBody>
        <Text>Eliminare impianto "{site.name}"?</Text>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="secondary"
          onClick={() => onClose(false)}
          disabled={isLoading}
        >
          Annulla
        </Button>
        <Button variant="primary" disabled={isLoading} onClick={handleConfirm}>
          Elimina
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteSiteModal;
