import { Flex, NotificationManager, Stack, Text } from '@alexpireddu/map-ui';
import { FC, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import HomeApplication from '../../components/HomeApplication';
import CitiesTable from './CitiesTable';

const Cities: FC = () => {
  useEffect(() => {
    // Controlla il flag nel local storage
    const comuneCreato = localStorage.getItem('comuneCreato');
    if (comuneCreato === 'true') {
      localStorage.removeItem('comuneCreato'); // Rimuovi il flag
      NotificationManager.notify({
        type: 'success',
        message: 'Comune creato',
      });
    }
  }, []);

  return (
    <HomeApplication browserTitle="Partner">
      <Container fluid className="pa__cities">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Flex>
              <Stack marginTop={24} marginBottom={24} spacing={4}>
                <Text as="h2">Tutti i comuni</Text>
              </Stack>
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <CitiesTable />
          </Col>
        </Row>
      </Container>
    </HomeApplication>
  );
};

export default Cities;
