import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@alexpireddu/map-ui';
import { FC, useCallback, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { createCity } from '../../actions/partnerActions';
import { useAction } from '../../hooks';
import NewCityModalForms from './NewCityModalForms';

export interface CreateCityModalProps {
  onClose: Function;
}

export interface CreateCityFormValues {
  name: string;
  regionId: string;
  provinceId: string;
}

const NewCityModal: FC<CreateCityModalProps> = ({ onClose }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const useFormMethods = useForm<CreateCityFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: undefined,
      provinceId: undefined,
      regionId: undefined,
    },
  });
  const { handleSubmit } = useFormMethods;

  const cityCreate = useAction(createCity);

  const handleOnSubmit: SubmitHandler<CreateCityFormValues> = useCallback(
    (data: CreateCityFormValues): void => {
      setLoading(true);
      cityCreate({
        name: data.name,
        province_id: data.provinceId,
      })
        .then(() => {
          setLoading(false);

          onClose(true);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [cityCreate, onClose]
  );

  return (
    <Modal size="lg">
      <FormProvider {...useFormMethods}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <ModalHeader>Nuovo comune</ModalHeader>
          <ModalBody>
            <NewCityModalForms isLoading={isLoading} />
          </ModalBody>
          <ModalFooter>
            <Button
              variant="secondary"
              onClick={() => onClose(false)}
              disabled={isLoading}
            >
              Annulla
            </Button>
            <Button type="submit" variant="primary" disabled={isLoading}>
              Crea
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default NewCityModal;
