import { Box, Card, Flex, Stack, Text } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { SettingsAccountBoxIcon } from '../../components';
import HomeApplication from '../../components/HomeApplication';
import { ConfigureMenuItem } from './ConfigureMenuItem';

const PageContainer = styled.div({
  display: 'flex',
});

const LeftSideCard = styled(Card)(() => ({
  height: `calc(100vh - 150px)`,
  width: '20%',
  margin: '24px',
}));

const RightSide = styled.div(() => ({
  height: `calc(100vh - 96px)`,
  overflowY: 'auto',
  width: '80%',
  padding: '24px 0',
}));

const PartnerConfiguration: FC = () => {
  return (
    <HomeApplication browserTitle="Configura">
      <PageContainer>
        <LeftSideCard size="lg">
          <Flex flexDirection={'row'}>
            <Box>
              <SettingsAccountBoxIcon />
            </Box>
            <Box ml="8px">
              <Text as="h3">Configurazioni</Text>
            </Box>
          </Flex>
          <Stack marginTop={8}>
            <ConfigureMenuItem
              link={`/configura/generale`}
              label="Generalità"
            />
            <ConfigureMenuItem
              link={`/configura/collaboratori`}
              label="Collaboratori"
            />
          </Stack>
        </LeftSideCard>

        <RightSide>
          <Outlet />
        </RightSide>
      </PageContainer>
    </HomeApplication>
  );
};

export default PartnerConfiguration;
