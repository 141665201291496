import _ from 'lodash';
import { FC, useEffect } from 'react';
import { getOnlineSites } from '../../actions';
import { useAction, useAppSelector, useCityParam } from '../../hooks';
import LiveSiteButton from '../LiveSiteButton';

const SingleSitePreview: FC = () => {
  const { currentCityId } = useCityParam();
  const videoSitesGet = useAction(getOnlineSites);
  const { sites } = useAppSelector((x) => ({ sites: x.sites }));

  useEffect(() => {
    if (!_.isNil(currentCityId)) {
      videoSitesGet(currentCityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCityId]);

  if (
    _.some(sites.data) &&
    _.filter(sites.data, (x) => x.active).length === 1
  ) {
    const site = _.head(sites.data);

    return <LiveSiteButton siteId={site!.id} siteName={site!.name} />;
  }

  return null;
};

export default SingleSitePreview;
