import { Stack } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { subscriberLogout } from '../../actions';
import { useAction } from '../../hooks';

const StyledLink = styled(Link)({
  fontSize: '0.875rem',
  lineHeight: 1.4,
  color: '#263238',
  padding: '8px 24px',
  margin: '0px -24px',
  '&:hover, &:focus': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
});

const LogoutLink = styled(StyledLink)({
  color: '#be0000',
  '&:hover, &:focus': {
    backgroundColor: 'rgba(190, 0, 0, 0.1)',
  },
});

const MenuLinks: FC = () => {
  const navigate = useNavigate();
  const logout = useAction(subscriberLogout);

  const handleLogout = useCallback(async () => {
    await logout();

    navigate('/');
  }, [navigate, logout]);

  return (
    <Stack>
      <StyledLink to="/support">Assistenza</StyledLink>
      <LogoutLink to={'/'} onClick={handleLogout}>
        Esci
      </LogoutLink>
    </Stack>
  );
};

export default MenuLinks;
