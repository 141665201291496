import {
  deleteDefaultInstitutionalImageV1,
  getCityUsersV1,
  getDefaultInstitutionalImageV1,
  uploadDefaultInstitutionalImageV1,
} from '../apis/cityApis';
import { actionFactory } from '../infrastructure/actionFactory';

export const deleteDefaultInstitutionalImage = (cityId: string) =>
  actionFactory(
    'DEFAULT_INSTITUTIONAL_DELETE',
    deleteDefaultInstitutionalImageV1(cityId)
  );

export const getDefaultInstitutionalImage = (cityId: string) =>
  actionFactory(
    'DEFAULT_INSTITUTIONAL_GET',
    getDefaultInstitutionalImageV1(cityId)
  );

export const uploadDefaultInstitutionalImage = (
  cityId: string,
  data: FormData
) =>
  actionFactory(
    'DEFAULT_INSTITUTIONAL_UPLOAD',
    uploadDefaultInstitutionalImageV1(cityId, data)
  );

export const getCityUsers = (cityId: string) =>
  actionFactory('CITY_USERS_GET', getCityUsersV1(cityId));
