import { getLedwallBaseUri } from '../infrastructure/environment';

export const openLedwallPreview = (siteId: string) => {
  const ledwallUri = `${getLedwallBaseUri()}/${siteId}`;

  let popup = window.open(
    ledwallUri,
    siteId,
    'width=512,height=384,titlebar=no,toolbar=no,directories=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,top=100'
  );

  return popup;
};
