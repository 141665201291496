import { SortType } from '@alexpireddu/map-ui/dist/components/Table/AdvancedTable';
import { ActionCreator } from 'redux';
import {
  CreateCityRequest,
  createCityV1,
  getCitiesPaginatedV1,
  getCitiesV1,
} from '../apis/cityApis';
import { getPartnerUsersV1, getPartnerV1 } from '../apis/userApi';
import { actionFactory } from '../infrastructure/actionFactory';
import { PublistaAction } from '../infrastructure/commonTypes';

export const createCity = (city: CreateCityRequest) =>
  actionFactory('CITY_CREATE', createCityV1(city));

export const getCities: ActionCreator<PublistaAction> = () =>
  actionFactory('CITIES_GET', getCitiesV1());

export const getPagedCities = (
  pageNumber: number,
  pageSize: number,
  sortProperty?: string,
  sortOrder?: SortType
) =>
  actionFactory(
    'PAGED_CITIES_GET',
    getCitiesPaginatedV1(pageNumber, pageSize, sortProperty, sortOrder)
  );

export const getPartnerUsers: ActionCreator<PublistaAction> = () =>
  actionFactory('PARTNER_USERS_GET', getPartnerUsersV1());

export const getPartner = () => actionFactory('PARTNER_GET', getPartnerV1());
