import { FC, useCallback, useState } from 'react';
import { Stack } from '@alexpireddu/map-ui';
import ImageUpload from './ImageUpload';
import { useFormContext } from 'react-hook-form';
import { WizardFormValues } from './WizardForm';

export type UploadingImageValues = {
  file: File;
};

export const uploadingImageFormValues = {
  file: undefined,
};

const UploadingImage: FC = () => {
  const { setValue, register, formState, getValues } =
    useFormContext<WizardFormValues>();
  const {
    errors: { file: fileErrors },
  } = formState;
  const registerInput = useCallback(
    () =>
      register('file', {
        required: 'Il contenuto è obbligatorio',
      }),
    [register]
  );

  const { file } = getValues();
  const [image, setImage] = useState<File>(file);

  return (
    <Stack direction="row" spacing={4}>
      <ImageUpload
        file={image}
        errors={fileErrors}
        {...registerInput()}
        onDropAccepted={(files: Array<File>) => {
          setValue('file', files[0]);
          setImage(files[0]);
        }}
      />
    </Stack>
  );
};

export default UploadingImage;
