import { Box, Card, Stack, Text } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import partnerImage from '../../../assets/abaco.png';
import { useAppSelector } from '../../../hooks';

const Image = styled.img({
  maxWidth: 170,
  width: '100%',
});

const Overview: FC = () => {
  const { partner } = useAppSelector((store) => ({
    partner: store.support.partner,
  }));

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box ml={12} mt={12} mb={24}>
            <Text as="h2">Generalità partner</Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box mb={24}>
            <Card size="xl">
              <Box pb={30}>
                <Image src={partnerImage} alt="partner" />
              </Box>
              <Stack direction="row" spacing={2} alignItems="center">
                <Text as="h2" marginBottom="0" lineHeight={'2rem'}>
                  {partner?.data?.name}
                </Text>
              </Stack>
            </Card>
          </Box>
        </Col>
      </Row>
    </Container>
  );
};

export default Overview;
