import { Button } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { NewCityIcon } from '../Icons';
import { useModalProvider } from '../ModalProvider';
import NewCityModal, { CreateCityModalProps } from './NewCityModal';

const ButtonStyled = styled(Button)({
  paddingLeft: '48px',
  paddingRight: '48px',
  textTransform: 'uppercase',
});

const NewCityButton: FC = () => {
  const navigate = useNavigate();

  const { openModal } = useModalProvider();

  const handleOpenEditModal = useCallback(() => {
    openModal<CreateCityModalProps>({
      //lazyImportFn: () => import('./NewCityModal'),
      component: NewCityModal,
      additionalProps: {
        onClose: (success: boolean) => {
          localStorage.setItem('comuneCreato', 'true');
          if (success) {
            if (window.location.pathname === '/home') {
              window.location.reload();
            } else {
              navigate('/home');
            }
          }
        },
      },
    });
  }, [openModal, navigate]);

  return (
    <>
      <ButtonStyled variant="primary" onClick={handleOpenEditModal}>
        <NewCityIcon />
        Nuovo comune
      </ButtonStyled>
    </>
  );
};

export default NewCityButton;
