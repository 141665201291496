import { FC, ReactNode } from 'react';

import _ from 'lodash';
import Helmet from 'react-helmet';
import EmergencyBanner from '../../areas/Emergencies/HeaderBanner';
import { useAppSelector, useCityParam } from '../../hooks';
import { Body } from './Body';
import { Footer } from './Footer';
import { Header } from './Header';

export interface ApplicationProps {
  header?: ReactNode;
  footer?: ReactNode;
  showFooter?: boolean;
}

const Application: FC<ApplicationProps> = ({
  children,
  header,
  footer,
  showFooter = false,
}) => {
  const { hasEmergencies } = useAppSelector((store) => ({
    hasEmergencies: _.some(store.emergencies.data),
  }));

  const { currentCityId } = useCityParam();

  return (
    <>
      <Helmet titleTemplate="Publista - %s" defaultTitle="Publista - partner" />

      <div>
        <EmergencyBanner
          emergencies={hasEmergencies && currentCityId !== undefined}
        />
        <Header>{header}</Header>
        <Body footerShown={showFooter} emergenciesBanner={hasEmergencies}>
          {children}
        </Body>
        <Footer footerShown={showFooter}>{footer}</Footer>
      </div>
    </>
  );
};

export default Application;
