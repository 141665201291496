import {
  AdvancedTable,
  HeaderCell,
  TableBody,
  TableHeader,
  useAdvancedTable,
} from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getPagedCities } from '../../actions';
import { CitiesPaginated } from '../../apis/cityApis';
import { useAction } from '../../hooks';
import NoResultsRow from './NoResultsRow';
import TableRow from './TableRow';

const CitiesTable: FC = () => {
  const [cities, setCities] = useState<CitiesPaginated>();
  const citiesGet = useAction(getPagedCities);

  const advancedTableProps = useAdvancedTable({
    sortProperty: 'name',
    sortOrder: 'asc',
    totalCount: cities?.total || 0,
    pageSize: 5,
  });

  const { currentPage, pageSize, sortProperty, sortOrder } = advancedTableProps;

  const loadCities = useCallback(() => {
    citiesGet(currentPage, pageSize, sortProperty, sortOrder).then(
      (resp: any) => setCities(resp.value.data)
    );
  }, [currentPage, pageSize, sortProperty, sortOrder, citiesGet]);

  useEffect(() => {
    loadCities();
  }, [loadCities]);

  const sortedCities = useMemo(
    () => _.sortBy(cities?.results, (x) => x.services.video),
    [cities?.results]
  );

  const lastToConfigure = useMemo(
    () => _.last(_.filter(sortedCities, (x) => !x.services.video)),
    [sortedCities]
  );

  return (
    <AdvancedTable isFullWidth {...advancedTableProps}>
      <TableHeader>
        <tr>
          <HeaderCell fixedWidth={150}>Stato</HeaderCell>
          <HeaderCell bindingProperty="name"> Nome comune </HeaderCell>
          <HeaderCell bindingProperty="province">Provincia</HeaderCell>
          <HeaderCell>&nbsp;</HeaderCell>
        </tr>
      </TableHeader>
      <TableBody>
        {_.map(sortedCities, (c) => (
          <TableRow
            key={c.id}
            city={c}
            lastToConfigure={lastToConfigure?.id === c.id}
          />
        ))}
        {!_.some(cities?.results) && <NoResultsRow />}
      </TableBody>
    </AdvancedTable>
  );
};

export default CitiesTable;
