import { Box, Button, Card, Flex, Text, Toggle } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import _ from 'lodash';
import { FC, useCallback } from 'react';
import { CitySite } from '../../../apis/sitesApi';
import { LiveSiteButton, useModalProvider } from '../../../components';
import { useCityParam } from '../../../hooks';
import { ReloadCallbackHandler } from '../../../infrastructure/commonTypes';
import { colors } from '../../../theming/theme';
import DeleteSiteModal, { DeleteSiteModalProps } from './DeleteSiteModal';
import ManageSiteModal, { ManageSiteModalProps } from './ManageSiteModal';

const openGoogleMapsPin = (lat: string, lng: string) => {
  // TODO link da rivedere
  window.open(`https://maps.google.com/maps?q=${lat},${lng}`, '_blank');
};

export interface SiteItemCardProps {
  site: CitySite;
  sitesList: CitySite[];
  siteNumber: number;
  onReloadSites?: ReloadCallbackHandler;
}

const ItemCard = styled(Card)({
  position: 'relative',
  border: 'solid 2px ' + colors.white,
  marginRight: '16px',
});

const SiteNumber = styled.span({
  border: 'solid 1px ' + colors.text400,
  borderRadius: '8px',
  padding: '0 10px',
  fontWeight: 700,
});

const SiteItemCard: FC<SiteItemCardProps> = ({
  site,
  sitesList,
  siteNumber,
  onReloadSites,
}) => {
  const { currentCityId } = useCityParam();
  const { openModal } = useModalProvider();

  const handleCloseModal = useCallback(
    (ricarica: boolean) => {
      if (ricarica && _.isFunction(onReloadSites)) {
        onReloadSites(true);
      }
    },
    [onReloadSites]
  );

  const handleOpenEditModal = useCallback(() => {
    openModal<ManageSiteModalProps>({
      //lazyImportFn: () => import('./ManageSiteModal'),
      component: ManageSiteModal,
      additionalProps: {
        onClose: handleCloseModal,
        site: site,
        inputMode: 'update',
        sitesList,
        currentCityId,
        siteNumber,
      },
    });
  }, [openModal, handleCloseModal, site, sitesList, currentCityId, siteNumber]);

  const handleOpenDeleteModal = useCallback(() => {
    openModal<DeleteSiteModalProps>({
      //lazyImportFn: () => import('./DeleteSiteModal'),
      component: DeleteSiteModal,
      additionalProps: {
        onClose: handleCloseModal,
        site: site,
        currentCityId,
      },
    });
  }, [openModal, handleCloseModal, site, currentCityId]);

  return (
    <>
      <Box mr="24px" mt="12px" mb="12px">
        <ItemCard>
          <Flex alignItems={'center'} mb="16px">
            <Box flexGrow={5}>
              <SiteNumber>{siteNumber}</SiteNumber>
            </Box>
          </Flex>
          <Box>
            <Text as="h4">{site.name}</Text>
          </Box>
          <Box mt="16px">
            <LiveSiteButton siteId={site.id} siteName={site.name} isFullwidth />
          </Box>
          <Box mt="16px">
            <Button
              variant="secondary"
              isFullWidth
              icon="map-marker-alt"
              onClick={() => openGoogleMapsPin(site.latitude, site.longitude)}
            >
              Vedi su mappa
            </Button>
          </Box>
          <Flex mt="8px" justifyContent={'space-between'}>
            <Box mr="4px">
              <Button
                variant="secondary"
                isFullWidth
                onClick={handleOpenEditModal}
              >
                Modifica
              </Button>
            </Box>
            <Box ml="4px">
              <Button
                variant="secondary"
                isFullWidth
                onClick={handleOpenDeleteModal}
              >
                Elimina
              </Button>
            </Box>
          </Flex>
          <Flex mt="16px" justifyContent={'space-between'}>
            <Box ml="16px">
              <Text as="span">{site.active ? 'Attivo' : 'Disattivo'}</Text>
            </Box>
            <Box ml="8px">
              <Toggle checked={site.active} disabled />
            </Box>
          </Flex>
        </ItemCard>
      </Box>
    </>
  );
};

export default SiteItemCard;
