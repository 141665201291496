import { FC, useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { createInstitutionalCampaign } from '../../actions/videoActions';
import CampaignBookingApplication from '../../components/CampaignBookingApplication';
import { useAction, useCityParam } from '../../hooks';
import {
  GeneralInformationValues,
  generalInformationInitialFormValues,
} from './GeneralInformation';
import {
  UploadingImageValues,
  uploadingImageFormValues,
} from './UploadingImage';

export type WizardFormValues = GeneralInformationValues & UploadingImageValues;

const WizardForm: FC = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const { currentCityId } = useCityParam();
  const navigate = useNavigate();
  const useFormMethods = useForm<WizardFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...generalInformationInitialFormValues,
      ...uploadingImageFormValues,
    },
  });
  const { handleSubmit } = useFormMethods;
  const createInsitutionalCampaign = useAction(createInstitutionalCampaign);

  const handleOnSubmit: SubmitHandler<WizardFormValues> = useCallback(
    (data) => {
      const { name, fromDate, toDate, file } = data;
      const formData = new FormData();
      formData.append('image', file);
      formData.append('from_date', fromDate);
      formData.append('to_date', toDate);
      formData.append('name', name);

      createInsitutionalCampaign(currentCityId, formData).then(() => {
        navigate(`/campagne/${currentCityId}`);
      });
    },
    [createInsitutionalCampaign, currentCityId, navigate]
  );

  return (
    <FormProvider {...useFormMethods}>
      <form ref={formRef} onSubmit={handleSubmit(handleOnSubmit)}>
        <CampaignBookingApplication>
          <Helmet title="Comunicazione comunale" />

          <div style={{ maxWidth: '1080px', margin: '0 auto' }}>
            <Outlet />
          </div>
        </CampaignBookingApplication>
      </form>
    </FormProvider>
  );
};

export default WizardForm;
