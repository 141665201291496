import { Box, Button, Flex, Text } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { getCityUsers } from '../../../actions';
import { CityUserInfo } from '../../../apis/cityApis';
import { useModalProvider } from '../../../components';
import { useAction, useCityParam } from '../../../hooks';
import CreateEmployeeModal, {
  CreateEmployeeModalProps,
} from './CreateEmployeeModal';
import EmployeeRecord from './EmployeeRecord';

const Employees: FC = () => {
  const [cityUsers, setCityUsers] = useState<Array<CityUserInfo>>([]);
  const { openModal } = useModalProvider();
  const { currentCityId } = useCityParam();
  const getUsers = useAction(getCityUsers);

  const loadUsers = useCallback(() => {
    if (!_.isNil(currentCityId)) {
      getUsers(currentCityId).then((resp: any) =>
        setCityUsers(resp.value.data)
      );
    }
  }, [currentCityId, getUsers]);

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadUsers = useCallback(
    (reload: boolean) => {
      if (reload) {
        loadUsers();
      }
    },
    [loadUsers]
  );

  const openCreateEmployeeModal = useCallback(() => {
    openModal<CreateEmployeeModalProps>({
      //lazyImportFn: () => import('./CreateEmployeeModal'),
      component: CreateEmployeeModal,
      additionalProps: {
        currentCityId: currentCityId!,
        onClose: reloadUsers,
      },
    });
  }, [openModal, reloadUsers, currentCityId]);

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box ml={12} mt={12} mb={24}>
            <Text as="h2">Collaboratori</Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button
            variant="primary"
            icon={'plus'}
            onClick={openCreateEmployeeModal}
            mb="24px"
          >
            Invita collaboratori
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Flex flexDirection={'column'}>
            {_.map(cityUsers, (c) => (
              <EmployeeRecord
                employee={c.user}
                key={c.user.id}
                onEmployeeDeleted={() => reloadUsers(true)}
              />
            ))}
          </Flex>
        </Col>
      </Row>
    </Container>
  );
};

export default Employees;
