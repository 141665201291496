import { Box, Button, Card, Flex, Stack, Text } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import _ from 'lodash';
import { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { uploadCityLogoV1 } from '../../../apis/cityApis';
import { CityLogo } from '../../../components';
import { useCurrentCity } from '../../../hooks';

const SameSizeButton = styled(Button)({
  width: '210px',
});

const Overview: FC = () => {
  const city = useCurrentCity();
  const inputLogoRef = useRef<HTMLInputElement>(null);
  const [update, setUpdate] = useState<number>(0);

  const handleClickUploadLogo = useCallback(() => {
    inputLogoRef?.current?.click();
  }, []);

  const handleChangeLogoUploaded = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      if (_.some(evt.target.files)) {
        const file = _.head(evt.target.files);
        const formData = new FormData();
        formData.append('file', file as any);

        uploadCityLogoV1(city?.id!, formData).then(() =>
          setUpdate(new Date().getTime())
        );
      }
    },
    [city?.id]
  );

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box ml={12} mt={12} mb={24}>
            <Text as="h2">Generalità comune</Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box mb={24}>
            <Card size="xl">
              <Text as="h3" marginBottom="24px">
                Comune di
              </Text>
              <Stack direction="row" spacing={2} alignItems="center">
                <CityLogo cityId={city?.id!} update={update} />
                <Stack>
                  <Text as="h3" marginBottom="0" lineHeight={'2rem'}>
                    {city?.name}
                  </Text>
                  <Text as="p">Provincia: {city?.province.name}</Text>
                </Stack>
              </Stack>
              <Flex justifyContent={'flex-end'} mt="80px">
                <Box mr={'24px'}>
                  <SameSizeButton
                    variant="secondary"
                    onClick={handleClickUploadLogo}
                  >
                    Carica Logo
                  </SameSizeButton>
                  <input
                    type={'file'}
                    style={{ display: 'none' }}
                    ref={inputLogoRef}
                    onChange={handleChangeLogoUploaded}
                  />
                </Box>
              </Flex>
            </Card>
          </Box>
        </Col>
      </Row>
    </Container>
  );
};

export default Overview;
