import { useCallback, useEffect, useState } from 'react';
import { useAction } from './useActions';
import { getOnlineSites } from '../actions';
import { useCityParam } from './useCityParam';
import _ from 'lodash';
import { CitySites } from '../apis/sitesApi';

export const useCitySites = () => {
  const [sites, setSites] = useState<CitySites>([]);
  const videoSitesGet = useAction(getOnlineSites);
  const { currentCityId } = useCityParam();

  const getCitySites = useCallback(() => {
    videoSitesGet(currentCityId!).then((res: any) => {
      const { data } = res.value;

      setSites(data as CitySites);
    });
  }, [currentCityId, videoSitesGet]);

  useEffect(() => {
    if (!_.isNil(currentCityId)) {
      getCitySites();
    }
  }, [getCitySites, currentCityId]);

  return { sites, getCitySites };
};
