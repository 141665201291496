import {
  configureSentryUser,
  resetSentryUser,
} from '../infrastructure/sentryConfiguration';
import { get, post, remove } from '../infrastructure/webRequest';

export interface UpdateSubscriberRequest {
  email: string;
  name: string;
  new_password: string;
  new_password_confirm: string;
  old_password: string;
}

export type UserRole = string;

export type UserRoles = Array<UserRole>;

export interface UserInfo {
  email: string;
  id: string | null;
  name: string;
  phone?: string;
  surname?: string;
  sso_user: boolean;
  roles: UserRoles;
}

export type Users = Array<UserInfo>;

export interface PartnerInfo {
  id: string | null;
  name: string;
}

export const getSubscriberRolesV1 = () =>
  get<UserRoles>('/v1/subscriber/roles');

export const updateSubscriberV1 = (subscriber: UpdateSubscriberRequest) =>
  post('/v1/subscriber/update', subscriber);

export const getSubscriberInfoV1 = () =>
  get<UserInfo>('/v1/subscriber/info').then((resp) => {
    const {
      data: { id, email, name, surname },
    } = resp;
    configureSentryUser(id!, email, name, surname);

    return resp;
  });

export const refreshTokenV1 = () => {
  get('/v1/subscriber/token/refresh');
};

export const subscriberLogoutV1 = () =>
  get(`/v1/subscriber/logout`).then((resp) => {
    resetSentryUser();

    return resp;
  });

export const getPartnerV1 = () => get<PartnerInfo>('/v1/partner');

export const getPartnerUsersV1 = () => get<Users>(`/v1/partner/users`);

export interface CheckUserRoleRequest {
  email: string;
  role: string;
  entity_related_role_id: string;
}

export const checkUserRoleV1 = (request: CheckUserRoleRequest) =>
  get<UserInfo>(
    `/v1/partner/users/check-role?email=${request.email}&role=${request.role}&entity_related_role_id=${request.entity_related_role_id}`
  );

export interface AddRoleToUserRequest {
  entity_related_role_id: string;
  role: string;
}

export const addRoleToUserV1 = (
  user_id: string,
  request: AddRoleToUserRequest
) => post(`/v1/partner/users/${user_id}/roles`, request);

export interface CreateUserRequest {
  entity_related_role_id: string;
  email: string;
  name: string;
  phone: string;
  role: string;
  surname: string;
}
export interface DeleteUserRoleRequest {
  entity_related_role_id: string;
  role: string;
}

export const createUserV1 = (user: CreateUserRequest) =>
  post('/v1/partner/users', user);

export const deleteUserRoleV1 = (
  user_id: string,
  request: DeleteUserRoleRequest
) =>
  remove(
    `/v1/partner/users/${user_id}/roles?entity_related_role_id=${request.entity_related_role_id}&role=${request.role}`
  );
