import { SortType } from '@alexpireddu/map-ui/dist/components/Table/AdvancedTable';
import _ from 'lodash';
import { PaginationResult } from '../infrastructure/dataTypes';
import { get, post, put, remove } from '../infrastructure/webRequest';
import { ProvinceInfo } from './supportApi';

export interface CityService {
  name: string;
  active: boolean;
}

export type CityServices = {
  paper: boolean;
  video: boolean;
};

export interface CityProvince {
  id: string;
  name: string;
}

export interface CityInfo {
  address: string;
  id: string;
  city_class: number;
  logo: string;
  mail: string;
  name: string;
  pec: string;
  phone: string;
  postcode: string;
  province: ProvinceInfo;
  services: CityServices;
}

export type Cities = Array<CityInfo>;

export type CitiesPaginated = PaginationResult<CityInfo>;

export interface CityUserRole {
  role: string;
}

export type CityUserRoles = Array<CityUserRole>;

export interface CityUser {
  email: string;
  id: string;
  name: string;
  surname: string;
}

export interface CityUserInfo {
  roles: CityUserRoles;
  user: CityUser;
}

export const getCityUsersV1 = (cityId: string) =>
  get<Array<CityUserInfo>>(`/v1/cityadmin/city/${cityId}/users`);

export const getCitiesPaginatedV1 = (
  pageNumber: number,
  pageSize: number,
  sortProperty?: string,
  sortOrder?: SortType
) => {
  const additionalParams: string[] = [];
  if (!_.isNil(sortProperty) && !_.isNil(sortOrder)) {
    additionalParams.push(`&sort_by=${sortProperty}`);
    additionalParams.push(`&sort_mode=${sortOrder === 'desc' ? 2 : 1}`);
  }

  return get<CitiesPaginated>(
    `/v1/partner/cities?page=${pageNumber}&size=${pageSize}${_.join(
      additionalParams,
      ''
    )}`
  );
};

export const getCitiesV1 = () => get<Cities>('/v1/partner/cities');

export const getCityLogoV1 = (cityId: string) =>
  get<string>(`/v1/cityadmin/city/${cityId}/logo`, undefined, {
    responseType: 'arraybuffer',
    responseEncoding: 'base64',
  }).then((resp) => new Blob([resp.data]));

export interface CreateCityRequest {
  name: string;
  province_id: string;
}

export const createCityV1 = (city: CreateCityRequest) =>
  post('/v1/partner/cities', city);

export const uploadCityLogoV1 = (cityId: string, data: FormData) =>
  put(`/v1/partner/cities/${cityId}/logo`, data);

export const deleteDefaultInstitutionalImageV1 = (cityId: string) =>
  remove(`/v1/partner/cities/${cityId}/video/institutionals/default`);

export const getDefaultInstitutionalImageV1 = (cityId: string) =>
  get<string>(
    `/v1/partner/cities/${cityId}/video/institutionals/default`,
    undefined,
    {
      responseType: 'arraybuffer',
      responseEncoding: 'base64',
    }
  ).then((resp) => new Blob([resp.data]));

export const uploadDefaultInstitutionalImageV1 = (
  cityId: string,
  data: FormData
) => put(`/v1/partner/cities/${cityId}/video/institutionals/default`, data);
