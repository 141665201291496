import _ from 'lodash';
import { Roles } from '../enums';
import { useAppSelector } from './storeHooks';

export const useCheckAuthorization = () => {
  const { user } = useAppSelector((store) => ({
    user: store.user,
  }));

  return (
    !_.isNil(user.data) &&
    !_.isNil(_.find(user.data.roles, (x) => x === Roles.PARTNER))
  );
};
