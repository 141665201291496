import { AxiosError } from 'axios';
import { ClientError } from '../store/initialState';

export const ERROR_TITLE = 'Si è verificato un errore';
export const ERROR_NO_ATIVE_SITE = 'Non ci sono impianti attivi';
export const STATUS_400_MESSAGE = 'Uno o più valori inseriti non sono validi';
export const STATUS_401_MESSAGE = 'Utente non autorizzato';
export const STATUS_403_MESSAGE =
  "L'utente non ha i permessi per eseguire questa operazione";
export const STATUS_404_MESSAGE = 'Not found';
export const STATUS_422_MESSAGE = "Non è stato possibile eseguire l'operazione";
export const STATUS_500_MESSAGE = "Errore durante l'esecuzione";

const parse400ErrorMessage = (actionType: string) => {
  switch (actionType) {
    case 'CITY_EMERGENCIES_UPDATE_REJECTED':
    case 'CITY_NEWS_UPDATE_REJECTED':
      return ERROR_NO_ATIVE_SITE;
    default:
      return STATUS_400_MESSAGE;
  }
};

const parse422ErrorMessage = (actionType: string) => {
  switch (actionType) {
    default:
      return STATUS_422_MESSAGE;
  }
};

export const extractResponseError = (
  actionType: string,
  payload: AxiosError
): ClientError | null => {
  const { message, response } = payload;

  const responseError: ClientError = {
    statusCode: response?.status,
    statusMessage: ERROR_TITLE,
    message,
  };

  switch (response?.status) {
    case 400:
      responseError.message = parse400ErrorMessage(actionType);
      break;
    case 401:
      responseError.message = STATUS_401_MESSAGE;
      break;
    case 404:
      responseError.message = STATUS_404_MESSAGE;
      break;
    case 422:
      responseError.message = parse422ErrorMessage(actionType);
      break;
    case 500:
      responseError.message = STATUS_500_MESSAGE;
      break;
    default:
      break;
  }

  return responseError;
};

export const parseAxiosError = (error: AxiosError): ClientError => {
  return {
    message: error.message,
    statusCode: error.response?.status,
    stackMessage: error.stack,
    statusMessage: error.response?.statusText,
  };
};
