import { FC, useEffect, useState } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import styled from '@emotion/styled';
import { Hint, Text } from '@alexpireddu/map-ui';
import { Icon as MapIcon } from '@alexpireddu/map-ui';
import { FieldError } from 'react-hook-form';
import clock from '../../assets/clock.svg';
import _ from 'lodash';
import { Bullet } from '../../components';
import uploadImage from '../../assets/uploadImage.svg';

type SVGImageProps = { width?: string | number; alignSelf?: string };

const SVGImage = styled.img<SVGImageProps>(
  ({ width = '60%', alignSelf = 'center' }) => ({
    width,
    alignSelf: alignSelf,
  })
);

const Container = styled.div({
  width: '100%',
  borderRadius: 12,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
});

const Footer = styled.div({
  flex: 1,
  backgroundColor: '#81868a',
  display: 'flex',
  alignItems: 'center',
});

const Skeletons = styled.div({ flex: 2, height: '100%' });
const SkeletonContainer = styled.div<{
  height: string;
  gap?: number;
  borderTop?: string;
}>(({ height, gap, borderTop }) => ({
  flex: 2,
  height,
  gap,
  borderTop,
  display: 'flex',
  alignItems: 'center',
  padding: '0 32px',
}));
const Skeleton = styled.div<{
  height: number;
  rounded?: boolean;
  flex: number;
}>(({ height, rounded, flex }) => ({
  height,
  ...(rounded && {
    borderRadius: height / 2,
  }),
  flex,
  backgroundColor: '#93989a',
}));

const ClockContainer = styled.div({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  borderLeft: '2px solid #93989a',
});

const RemoveButton = styled.button({
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  position: 'absolute',
  right: 16,
  top: 16,
  color: 'white',
  cursor: 'pointer',
  zIndex: 9,
  width: 36,
  height: 36,
});

const UploadRoot = styled.div<{
  hasImage?: boolean;
}>(({ hasImage }) => ({
  width: '100%',
  display: 'flex',
  aspectRatio: '16 / 9',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: '#cfd8dc',
  cursor: 'pointer',
  gap: 12,
  ...(hasImage && {
    backgroundColor: '#000000',
  }),
}));

const Image = styled.img({
  position: 'absolute',
  maxWidth: '100%',
  aspectRatio: '16 / 9',
  objectFit: 'contain',
});

const Add = styled.img({
  width: 70,
});

const StyledBullet = styled(Bullet)({
  margin: '2px 4px',
  backgroundColor: '#607d8b',
  width: 6,
  height: 6,
});

export type ImageUploadProps = Pick<DropzoneOptions, 'onDropAccepted'> & {
  file?: File;
  errors: FieldError | undefined;
};

const ImageUpload: FC<ImageUploadProps> = ({
  onDropAccepted,
  file,
  errors,
}) => {
  const [URI, setURI] = useState('');
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    onDropAccepted: onDropAccepted,
  });

  useEffect(() => {
    if (file) {
      setURI(URL.createObjectURL(file));
    }

    return () => {
      setURI((last) => {
        URL.revokeObjectURL(last);
        return '';
      });
    };
  }, [file]);

  return (
    <Container>
      <UploadRoot {...getRootProps()} hasImage={Boolean(URI)}>
        {!URI && (
          <>
            <input {...getInputProps()} />
            <Text as="h2" textAlign="center">
              Carica o trascina qui il tuo contenuto
            </Text>
            <Text textAlign="center" color="#607d8b" marginBottom={12}>
              Immagine JPEG o PNG <StyledBullet /> Dimensione massima 5 MB{' '}
              <StyledBullet />
              Formato 16:9
            </Text>
            <Add src={uploadImage} />
            {
              <Hint
                invalidMessage={errors?.message}
                isInvalid={!_.isNil(errors)}
              />
            }
          </>
        )}
        {URI && (
          <>
            <RemoveButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                onDropAccepted && onDropAccepted([], e.nativeEvent);
              }}
            >
              <MapIcon icon="trash" color="#fff" />
            </RemoveButton>
            <Image src={URI} alt="preview of uploaded file" />
          </>
        )}
      </UploadRoot>
      <Footer>
        <Skeletons>
          <SkeletonContainer height="60%">
            <Skeleton height={20} rounded flex={1} />
          </SkeletonContainer>
          <SkeletonContainer
            height="40%"
            gap={16}
            borderTop="2px solid #93989a"
          >
            <Skeleton height={15} flex={1} />
            <Skeleton height={8} flex={2} />
          </SkeletonContainer>
        </Skeletons>
        <ClockContainer>
          <SVGImage width={142} src={clock} />
        </ClockContainer>
      </Footer>
    </Container>
  );
};

export default ImageUpload;
