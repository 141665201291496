import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NotificationManager,
  Text,
} from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC, useCallback, useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { addRoleToUser, checkUserRole, createUser } from '../../../actions';
import { UserInfo } from '../../../apis/userApi';
import { Roles } from '../../../enums';
import { useAction, useAppSelector } from '../../../hooks';
import CheckUserModalForm from './CheckUserModalForm';
import CreateEmployeeModalForm from './CreateEmployeeModalForm';

export interface CreateEmployeeModalProps {
  onClose: Function;
}

export interface CreateEmployeeModalFormValues {
  id?: string;
  name?: string;
  surname?: string;
  email?: string;
}

const ViewMode = {
  CHECK_USER: 'check_user',
  CREATE_USER: 'create_user',
  UPDATE_USER: 'update_user',
  SUCCESS: 'success',
};

const CreateEmployeeModal: FC<CreateEmployeeModalProps> = ({ onClose }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [checkError, setCheckError] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<string>(ViewMode.CHECK_USER);
  const formRef = useRef<HTMLFormElement>(null);
  const useFormMethods = useForm<CreateEmployeeModalFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      id: undefined,
      name: undefined,
      surname: undefined,
      email: undefined,
    },
  });
  const { handleSubmit, setValue, getValues } = useFormMethods;
  const userCreate = useAction(createUser);
  const userCheckRole = useAction(checkUserRole);
  const userAddRole = useAction(addRoleToUser);
  const { email, name, surname, id: userId } = getValues();
  const { partner } = useAppSelector((store) => ({
    partner: store.support.partner,
  }));

  const handleBack = useCallback(() => {
    setViewMode(ViewMode.CHECK_USER);
    setValue('id', undefined);
    setValue('name', undefined);
    setValue('surname', undefined);
  }, [setValue]);

  const handleOnSubmit: SubmitHandler<CreateEmployeeModalFormValues> =
    useCallback(
      (data): void => {
        setLoading(true);

        if (viewMode === ViewMode.CHECK_USER) {
          userCheckRole({
            email: data.email!,
            role: Roles.PARTNER,
            entity_related_role_id: partner?.data?.id,
          })
            .then((resp: any) => {
              setLoading(false);
              setCheckError(false);
              if (_.isNil(resp.value.data) || resp.value.data === '') {
                setViewMode(ViewMode.CREATE_USER);
              } else {
                const existingUser = resp.value.data as UserInfo;
                setValue('name', existingUser.name);
                setValue('surname', existingUser.surname!);
                setValue('id', existingUser.id!);
                setViewMode(ViewMode.UPDATE_USER);
              }
            })
            .catch(() => {
              setLoading(false);
              setCheckError(true);
            });
        }

        if (viewMode === ViewMode.CREATE_USER) {
          userCreate({
            email: data.email!,
            name: data.name!,
            role: Roles.PARTNER,
            surname: data.surname!,
            entity_related_role_id: partner?.data?.id,
          })
            .then(() => {
              setLoading(false);

              NotificationManager.notify({
                type: 'success',
                message: 'Collaboratore invitato',
              });

              onClose(true);
            })
            .catch(() => {
              setLoading(false);
            });
        }

        if (viewMode === ViewMode.UPDATE_USER) {
          userAddRole(userId!, {
            role: Roles.PARTNER,
            entity_related_role_id: partner?.data?.id,
          }).then(() => {
            setLoading(false);

            setViewMode(ViewMode.SUCCESS);
          });
        }
      },
      [
        onClose,
        setValue,
        userAddRole,
        userCheckRole,
        userCreate,
        partner,
        userId,
        viewMode,
      ]
    );

  return (
    <Modal size="lg">
      <FormProvider {...useFormMethods}>
        <form onSubmit={handleSubmit(handleOnSubmit)} ref={formRef}>
          <ModalHeader>Invita collaboratore</ModalHeader>

          {viewMode === ViewMode.CHECK_USER && (
            <>
              <ModalBody>
                <CheckUserModalForm isLoading={isLoading} error={checkError} />
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="secondary"
                  onClick={() => onClose(false)}
                  disabled={isLoading}
                >
                  Annulla
                </Button>
                <Button variant="primary" disabled={isLoading} type="submit">
                  Avanti
                </Button>
              </ModalFooter>
            </>
          )}

          {viewMode === ViewMode.CREATE_USER && (
            <>
              <ModalBody>
                <CreateEmployeeModalForm isLoading={isLoading} />
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="secondary"
                  onClick={handleBack}
                  disabled={isLoading}
                >
                  Indietro
                </Button>
                <Button variant="primary" disabled={isLoading} type="submit">
                  Invita
                </Button>
              </ModalFooter>
            </>
          )}

          {viewMode === ViewMode.UPDATE_USER && (
            <>
              <ModalBody>
                <Flex flexDirection={'column'} alignItems="center">
                  <Box>
                    <Text>{email}</Text>
                  </Box>
                  <Box>
                    <Text fontSize={'1.2rem'}>
                      Vuoi invitare{' '}
                      <span style={{ fontWeight: '700' }}>
                        {name} {surname}
                      </span>
                      ?
                    </Text>
                  </Box>
                </Flex>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="secondary"
                  onClick={handleBack}
                  disabled={isLoading}
                >
                  Indietro
                </Button>
                <Button variant="primary" disabled={isLoading} type="submit">
                  Invita
                </Button>
              </ModalFooter>
            </>
          )}

          {viewMode === ViewMode.SUCCESS && (
            <>
              <ModalBody>
                <Flex justifyContent="center">
                  <Text fontSize={'1.2rem'}>Collaboratore invitato!</Text>
                </Flex>
              </ModalBody>
              <ModalFooter>
                <span></span>
                <Button
                  variant="primary"
                  onClick={() => onClose(true)}
                  disabled={isLoading}
                >
                  Fine
                </Button>
              </ModalFooter>
            </>
          )}
        </form>
      </FormProvider>
    </Modal>
  );
};

export default CreateEmployeeModal;
