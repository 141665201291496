import { Box, Button, Flex, Text } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC, useCallback, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { getSiteFormats } from '../../../actions';
import { useModalProvider } from '../../../components';
import {
  useAction,
  useAppSelector,
  useCityParam,
  useCitySites,
} from '../../../hooks';
import ManageSiteModal, { ManageSiteModalProps } from './ManageSiteModal';
import SiteItemCard from './SiteItemCard';

const Sites: FC = () => {
  const { currentCityId } = useCityParam();
  const { sites, getCitySites } = useCitySites();
  const formatsGet = useAction(getSiteFormats);
  const { openModal } = useModalProvider();
  const { formats } = useAppSelector((store) => ({
    formats: store.support.siteFormats,
  }));

  useEffect(() => {
    const { fetching, fetched, error } = formats;

    if (_.isNil(error) && !fetching && !fetched) {
      formatsGet();
    }
  }, [formatsGet, formats]);

  const closeCreateSiteModal = useCallback(
    (reload: boolean) => {
      if (reload) {
        getCitySites();
      }
    },
    [getCitySites]
  );

  const handleOpenCreateSiteModal = useCallback(() => {
    openModal<ManageSiteModalProps>({
      //lazyImportFn: () => import('./ManageSiteModal'),
      component: ManageSiteModal,
      additionalProps: {
        onClose: closeCreateSiteModal,
        inputMode: 'create',
        currentCityId,
        sitesList: sites,
        siteNumber: sites.length + 1,
      },
    });
  }, [openModal, closeCreateSiteModal, sites, currentCityId]);

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box ml={12} mt={12} mb={24}>
            <Text as="h2">Impianti</Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button
            variant="primary"
            size="md"
            mb="16px"
            icon={'plus'}
            onClick={handleOpenCreateSiteModal}
          >
            Aggiungi impianto
          </Button>
          <Flex flexDirection={'row'}>
            {_.map(
              _.sortBy(sites, (s) => s.created_at),
              (s, idx) => (
                <SiteItemCard
                  site={s}
                  key={idx}
                  siteNumber={idx + 1}
                  sitesList={sites}
                  onReloadSites={getCitySites}
                />
              )
            )}
          </Flex>
        </Col>
      </Row>
    </Container>
  );
};

export default Sites;
