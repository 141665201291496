import styled from '@emotion/styled';
import _ from 'lodash';
import { FC } from 'react';
import placeholder from '../../assets/image-placeholder-w35.jpg';
import { useCityLogo } from '../../hooks';
import { colors } from '../../theming/theme';

export interface CityLogoProps {
  cityId: string;
  update?: number;
}

const CityImage = styled.img({
  height: '60px',
  width: '60px',
  padding: '3px',
  margin: '0',
  objectFit: 'contain',
  border: 'solid 1px ' + colors.text300,
  borderRadius: '50%',
});

const CityLogo: FC<CityLogoProps> = ({ cityId, update }) => {
  const uri = useCityLogo(cityId, update);

  return (
    <CityImage
      alt="Logo comune"
      src={_.isNil(uri) ? placeholder : uri}
      title="Logo comune"
    />
  );
};

export default CityLogo;
