import { NotificationContainer } from '@alexpireddu/map-ui';
import { Navigate, Outlet } from 'react-router-dom';
import Booking from '../areas/Booking';
import PlanCommunication from '../areas/Booking/PlanCommunication';
import Recap from '../areas/Booking/Recap';
import Campaigns from '../areas/Campaigns';
import Cities from '../areas/Cities';
import CityConfiguration from '../areas/CityConfiguration';
import Defaults from '../areas/CityConfiguration/Defaults';
import CityEmployees from '../areas/CityConfiguration/Employees';
import Overview from '../areas/CityConfiguration/Overview';
import Sites from '../areas/CityConfiguration/Sites';
import Emergencies from '../areas/Emergencies';
import News from '../areas/News';
import PartnerConfiguration from '../areas/PartnerConfiguration';
import Employees from '../areas/PartnerConfiguration/Employees';
import PartnerOverview from '../areas/PartnerConfiguration/Overview';
import Support from '../areas/Support';
import {
  AccessDeniedPage,
  CheckAuthorization,
  DefaultErrorPage,
  ErrorBoundary,
  NotFoundErrorPage,
} from '../components';
import Bootstrapper from '../components/Bootstrapper';
import { sentryCreateBrowserRouter } from '../infrastructure/sentryConfiguration';

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <Bootstrapper>
          <NotificationContainer />
          <Outlet />
        </Bootstrapper>
      </ErrorBoundary>
    ),
    errorElement: <DefaultErrorPage />,
    children: [
      {
        path: 'unauthorized',
        element: <AccessDeniedPage />,
      },
      {
        path: '/',
        element: <CheckAuthorization />,
        children: [
          {
            path: '*',
            children: [
              {
                index: true,
                element: <Navigate to="home" />,
              },
              {
                path: 'home',
                element: <Cities />,
              },
              {
                path: 'campagne',
                element: <Navigate to="campagne" />,
              },
              {
                path: 'configura',
                element: <PartnerConfiguration />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="generale" />,
                  },
                  {
                    path: 'generale',
                    element: <PartnerOverview />,
                  },
                  {
                    path: 'collaboratori',
                    element: <Employees />,
                  },
                ],
              },
              {
                path: 'configura/:currentCityId/*',
                element: <CityConfiguration />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="generale" />,
                  },
                  {
                    path: 'generale',
                    element: <Overview />,
                  },
                  {
                    path: 'impianti',
                    element: <Sites />,
                  },
                  {
                    path: 'collaboratori',
                    element: <CityEmployees />,
                  },
                  {
                    path: 'default-media',
                    element: <Defaults />,
                  },
                ],
              },
              {
                path: 'campagne/',
                element: <Campaigns />,
              },
              {
                path: 'campagne/:currentCityId',
                element: <Campaigns />,
              },
              {
                path: 'news/',
                element: <News />,
              },
              {
                path: 'news/:currentCityId',
                element: <News />,
              },
              {
                path: 'news/:currentCityId/edit',
                element: <News editMode />,
              },
              {
                path: 'emergenze/',
                element: <Emergencies />,
              },
              {
                path: 'emergenze/:currentCityId',
                element: <Emergencies />,
              },
              {
                path: 'emergenze/:currentCityId/edit',
                element: <Emergencies editMode />,
              },
              {
                path: 'prenota/:currentCityId/*',
                element: <Booking />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="plan" />,
                  },
                  {
                    path: 'plan',
                    element: <PlanCommunication />,
                  },
                  {
                    path: 'recap',
                    element: <Recap />,
                  },
                ],
              },
              {
                path: 'support/',
                element: <Support />,
              },
              {
                path: '*',
                element: <NotFoundErrorPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
