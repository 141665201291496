import { ActionCreator } from 'redux';
import {
  getFormatsV1,
  getProvinceInfoV1,
  getProvincesByRegionV1,
  getRegionsV1,
} from '../apis/supportApi';
import { actionFactory } from '../infrastructure/actionFactory';
import { PublistaAction } from '../infrastructure/commonTypes';

export const getProvinceInfo: ActionCreator<PublistaAction> = (id: string) =>
  actionFactory('PROVINCE_INFO_GET', getProvinceInfoV1(id));

export const getSiteFormats: ActionCreator<PublistaAction> = () =>
  actionFactory('SITE_FORMATS_GET', getFormatsV1());

export const getProvince = (id: string) =>
  actionFactory('PROVINCE_INFO_GET', getProvinceInfoV1(id));

export const getProvinces = (regionId: string) =>
  actionFactory('PROVINCES_GET', getProvincesByRegionV1(regionId));

export const getRegions = () => actionFactory('REGIONS_GET', getRegionsV1());
