import { FC, SVGAttributes } from 'react';
import IconSvg from './IconSvg';

const ArrowBackIcon: FC<SVGAttributes<SVGElement>> = (props) => (
  <IconSvg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
    {...props}
  >
    <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
  </IconSvg>
);

export default ArrowBackIcon;
