import { Card, Text } from '@alexpireddu/map-ui';
import ImageUpload from './ImageUpload';
import { useEffect, useState, FC } from 'react';
import { useCityParam, useDefaultInstitutionalImage } from '../../../hooks';
import _ from 'lodash';

const DefaultInstitutional: FC = () => {
  const [image, setImage] = useState<File>();
  const { currentCityId } = useCityParam();
  const {
    getDefaultInstitutionalImg,
    uploadDefaultInstitutionalImg,
    deleteDefaultInstitutionalImg,
  } = useDefaultInstitutionalImage();

  useEffect(() => {
    getDefaultInstitutionalImg(currentCityId!).then((res: any) => {
      if (!_.isNil(res.value) && (res.value as Blob).size !== 0) {
        var f = new File([res.value], 'default');
        setImage(f);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetImage = (files: Array<File>) => {
    if (files[0] !== undefined) {
      const data = new FormData();
      data.append('image', files[0]);
      setImage(files[0]);
      uploadDefaultInstitutionalImg(currentCityId!, data);
    } else {
      deleteDefaultInstitutionalImg(currentCityId)!.then(() => {
        setImage(undefined);
      });
    }
  };

  return (
    <Card size="xl">
      <Text as="h3" marginBottom="24px">
        Campagna istituzionale
      </Text>
      <ImageUpload file={image} onDropAccepted={handleSetImage} />
    </Card>
  );
};

export default DefaultInstitutional;
