import { ActionCreator } from 'redux';
import {
  AddRoleToUserRequest,
  addRoleToUserV1,
  CheckUserRoleRequest,
  checkUserRoleV1,
  CreateUserRequest,
  createUserV1,
  DeleteUserRoleRequest,
  deleteUserRoleV1,
  getSubscriberInfoV1,
  getSubscriberRolesV1,
  refreshTokenV1,
  subscriberLogoutV1,
  UserInfo,
} from '../apis/userApi';
import { actionFactory } from '../infrastructure/actionFactory';
import { PublistaAction } from '../infrastructure/commonTypes';

export const getSubscriberRoles = () =>
  actionFactory('SUBSCRIBER_ROLES_GET', getSubscriberRolesV1());

export const getSubscriberInfo: ActionCreator<PublistaAction> = () =>
  actionFactory('SUBSCRIBER_INFO_GET', getSubscriberInfoV1());

export const setCurrentUser: ActionCreator<PublistaAction> = (user: UserInfo) =>
  actionFactory('SUBSCRIBER_INFO_SET', user);

export const refreshSubscriberToken: ActionCreator<PublistaAction> = () =>
  actionFactory('SUBSCRIBER_TOKEN_REFRESH', refreshTokenV1());

export const subscriberLogout: ActionCreator<PublistaAction> = () =>
  actionFactory('SUBSCRIBER_LOGOUT', subscriberLogoutV1());

export const checkUserRole = (request: CheckUserRoleRequest) =>
  actionFactory('USER_ROLE_CHECK', checkUserRoleV1(request), false);

export const addRoleToUser = (user_id: string, request: AddRoleToUserRequest) =>
  actionFactory('USER_ROLE_ADD', addRoleToUserV1(user_id, request));

export const createUser = (user: CreateUserRequest) =>
  actionFactory('USER_CREATE', createUserV1(user));

export const deleteUserRole = (
  userId: string,
  request: DeleteUserRoleRequest
) => actionFactory('USER_ROLE_DELETE', deleteUserRoleV1(userId, request));
