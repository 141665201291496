import { Card, Stack, Text } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC } from 'react';
import { CitySiteNews } from '../../apis/newsApis';
import NewsRow from './NewsRow';

export interface NewsListProps {
  news: CitySiteNews;
  onEdit: () => void;
}

const NewsList: FC<NewsListProps> = ({ news, onEdit }) => {
  return (
    <Card size="lg">
      <Stack spacing={2}>
        <Text as="h3">Programmazione</Text>
        {_.map(news, (n) => (
          <NewsRow key={n.id} newsItem={n} onEdit={onEdit} disabled={false} />
        ))}
        {!_.some(news) && <NewsRow onEdit={onEdit} disabled={false} />}
      </Stack>
    </Card>
  );
};

export default NewsList;
