import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getCityLogoV1 } from '../apis/cityApis';
import { getOriginalImageV1, getResizedImageV1 } from '../apis/imagesApi';

export const useResizedImage = (cityId: string, imageId: string) => {
  const [uri, setURI] = useState<string>();

  useEffect(() => {
    getResizedImageV1(cityId, imageId).then((r) => {
      if (r.size !== 0) {
        setURI(URL.createObjectURL(r));
      }
    });

    return () => {
      if (!_.isNil(uri)) {
        URL.revokeObjectURL(uri!);
      }

      return undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityId, imageId]);

  return uri;
};

export const useOriginalImage = (cityId: string, imageId: string) => {
  const [uri, setURI] = useState<string>();

  useEffect(() => {
    getOriginalImageV1(cityId, imageId).then((r) => {
      if (r.size !== 0) {
        setURI(URL.createObjectURL(r));
      }
    });

    return () => {
      if (!_.isNil(uri)) {
        URL.revokeObjectURL(uri!);
      }

      return undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return uri;
};

export const useCityLogo = (cityId: string, update?: number) => {
  const [uri, setURI] = useState<string>();

  useEffect(() => {
    getCityLogoV1(cityId).then((r) => {
      if (r.size !== 0) {
        setURI(URL.createObjectURL(r));
      }
    });

    return () => {
      if (!_.isNil(uri)) {
        URL.revokeObjectURL(uri!);
      }

      return undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityId, update]);

  return uri;
};
