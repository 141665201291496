import { Card, Stack, Text } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC } from 'react';
import { CitySiteNews } from '../../apis/newsApis';
import EmergencyRow from './EmergencyRow';
import { useVideoService } from '../../hooks';
import { colors } from '../../theming/theme';
import styled from '@emotion/styled';

export interface EmergenciesListProps {
  emergencies: CitySiteNews;
  onEdit: () => void;
}

const EmergenciesCard = styled(Card)({
  backgroundColor: colors.danger200,
});

const EmergenciesList: FC<EmergenciesListProps> = ({ emergencies, onEdit }) => {
  const videoService = useVideoService();

  return (
    <EmergenciesCard size="lg">
      <Stack spacing={2}>
        <Text as="h3" color={colors.white}>
          Programmazione
        </Text>
        {_.map(emergencies, (n) => (
          <EmergencyRow
            key={n.id}
            item={n}
            onEdit={onEdit}
            disabled={!videoService}
          />
        ))}
        {!_.some(emergencies) && (
          <EmergencyRow onEdit={onEdit} disabled={false} />
        )}
      </Stack>
    </EmergenciesCard>
  );
};

export default EmergenciesList;
