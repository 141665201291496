import { Box, Button, Flex, Text } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC, useCallback, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { getPartnerUsers } from '../../../actions';
import { useModalProvider } from '../../../components';
import { useActions, useAppSelector } from '../../../hooks';
import CreateEmployeeModal, {
  CreateEmployeeModalProps,
} from './CreateEmployeeModal';
import EmployeeRecord from './EmployeeRecord';

const Employees: FC = () => {
  const { employees } = useAppSelector((store) => ({
    employees: store.employees,
  }));
  const [getUsers] = useActions([getPartnerUsers]);
  const { openModal } = useModalProvider();

  useEffect(() => {
    const { fetching, fetched, error } = employees;
    if (!fetched && !fetching && _.isNil(error)) {
      getUsers();
    }
  }, [employees, getUsers]);

  const reloadUsers = useCallback(
    (reload: boolean) => {
      if (reload) {
        getUsers();
      }
    },
    [getUsers]
  );

  const openCreateEmployeeModal = useCallback(() => {
    openModal<CreateEmployeeModalProps>({
      //lazyImportFn: () => import('./CreateEmployeeModal'),
      component: CreateEmployeeModal,
      additionalProps: {
        onClose: reloadUsers,
      },
    });
  }, [openModal, reloadUsers]);

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box ml={12} mt={12} mb={24}>
            <Text as="h2">Collaboratori</Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button
            variant="primary"
            icon={'plus'}
            onClick={openCreateEmployeeModal}
            mb="24px"
          >
            Invita collaboratori
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Flex flexDirection={'column'}>
            {_.map(employees.data, (e) => (
              <EmployeeRecord employee={e} key={e.id} />
            ))}
          </Flex>
        </Col>
      </Row>
    </Container>
  );
};

export default Employees;
