import { Box, Button } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import _ from 'lodash';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {
  Application,
  ArrowBackIcon,
  CitySelect,
  Header,
  Logo,
  UserMenu,
} from '..';
import { colors } from '../../theming/theme';
import Divider from './Divider';
import Navigation from './Navigation';
import NewContentButton from './NewContentButton';
import SingleSitePreview from './SingleSitePreview';

export interface DefaultApplicationProps {
  browserTitle?: string;
}

const ArrowButton = styled(Button)({
  '&:hover,&:active,&:focus': {
    backgroundColor: colors.white,
  },
  padding: '7px 5px 5px 5px',
  marginRight: '0px',
  borderRadius: '12px',
});

const DefaultApplication: FC<DefaultApplicationProps> = ({
  children,
  browserTitle,
}) => {
  const navigate = useNavigate();

  return (
    <>
      {!_.isNil(browserTitle) && <Helmet title={browserTitle} />}

      <Application
        header={
          <Header
            leftContent={
              <>
                <Box>
                  <Logo clickable onClick={() => navigate('/')} />
                </Box>
                <Box pr={24} pl={40}>
                  <Divider />
                </Box>
                <ArrowButton variant="secondary" onClick={() => navigate('/')}>
                  <ArrowBackIcon />
                </ArrowButton>
                <Box ml="16px">
                  <CitySelect size="md" />
                </Box>
                <Box ml="16px">
                  <SingleSitePreview />
                </Box>
              </>
            }
            rightContent={
              <>
                <Navigation />
                <Box mr="24px" ml="24px">
                  <NewContentButton />
                </Box>
                <Box>
                  <UserMenu />
                </Box>
              </>
            }
          />
        }
      >
        {children}
      </Application>
    </>
  );
};

export default DefaultApplication;
