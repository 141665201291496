import { Tooltip } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { CityInfo } from '../../apis/cityApis';
import { Badge, EditIcon, SettingsIcon } from '../../components';
import { NavigationItem } from '../../components/DefaultApplication/NavigationItem';
import { colors } from '../../theming/theme';

export interface TableRowProps {
  city: CityInfo;
  lastToConfigure?: boolean;
}

const DetailLink = styled.span({
  textDecoration: 'underline',
  cursor: 'pointer',
});

const applyConfigureStyle = (last?: boolean) => {
  if (last) {
    return {
      style: {
        borderBottom: 'solid 3px ' + colors.grey300,
      },
    };
  }
};

const TableRow: FC<TableRowProps> = ({ city, lastToConfigure }) => {
  return (
    <tr>
      <td {...applyConfigureStyle(lastToConfigure)}>
        <Tooltip content={<span>Configura</span>}>
          <DetailLink>
            <NavigationItem
              link={`/configura/${city.id}`}
              label={city.services.video ? <SettingsIcon /> : <EditIcon />}
            />
          </DetailLink>
        </Tooltip>
        <Badge variant="info" />
      </td>
      <td {...applyConfigureStyle(lastToConfigure)}>
        <DetailLink>
          <NavigationItem link={`/campagne/${city.id}`} label={city.name} />
        </DetailLink>
      </td>
      <td {...applyConfigureStyle(lastToConfigure)}>{city.province.name}</td>
      <td {...applyConfigureStyle(lastToConfigure)} />
    </tr>
  );
};

export default TableRow;
